import { Box, styled, Typography } from '@mui/material';
import { Property, SubmodelElementCollection, File } from 'api/v3/aas_core_meta/types';
import { DataRow } from 'components/basics/DataRow';

type MarkingsComponentProps = {
    readonly submodelElement?: SubmodelElementCollection;
    readonly hasDivider?: boolean;
};

const StyledFileImg = styled('img')(({ theme }) => ({
    display: 'block',
    objectFit: 'scale-down',
    objectPosition: 'center',
    width: '100%',
    aspectRatio: '1',
    padding: theme.spacing(1),
}));

const StyledMarkingWrapper = styled(Box)(() => ({
    width: 'calc(25% - 15px)',
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 1120px)': {
        width: 'calc(50% - 10px)',
    },
}));

export function MarkingsComponent(props: MarkingsComponentProps) {
    const markings: Array<SubmodelElementCollection> = Object.values(
        props.submodelElement?.value || {},
    ) as Array<SubmodelElementCollection>;

    // Iterate through all markings
    const markingImages = markings.map((el, index) => {
        let file: File | undefined;
        let name: Property | undefined;
        let additionalText: Property | undefined;

        // Iterate through single marking properties
        Object.values(el.value || {}).forEach((markingPart) => {
            switch (markingPart.idShort) {
                case 'MarkingFile':
                    file = markingPart as File;
                    break;
                case 'MarkingName':
                    name = markingPart as Property;
                    break;
                case 'MarkingAdditionalText':
                    additionalText = markingPart as Property;
                    break;
            }
        });
        // Build single marking
        return (
            !!file &&
            file.contentType.startsWith('image') && (
                <StyledMarkingWrapper key={index} sx={{ boxShadow: 2 }}>
                    <StyledFileImg src={file.value} />
                    {(!!name || !!additionalText) && (
                        <Box sx={{ backgroundColor: 'grey.200', p: 1, flexGrow: '1' }}>
                            {!!name && <Typography>{name.value}</Typography>}
                            {!!additionalText && (
                                <Typography variant="body2" color="text.secondary">
                                    {additionalText.value}
                                </Typography>
                            )}
                        </Box>
                    )}
                </StyledMarkingWrapper>
            )
        );
    });
    // render all
    return (
        <DataRow title={props.submodelElement?.idShort} hasDivider={props.hasDivider}>
            <Box display="flex" gap="20px" flexWrap="wrap" sx={{ my: 1 }}>
                {markingImages}
            </Box>
        </DataRow>
    );
}
