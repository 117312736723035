export enum SubmodelElementSemanticId {
    Address = '0173-1#02-AAQ832#005',
    Markings = 'https://admin-shell.io/zvei/nameplate/1/0/Nameplate/Markings',
    ContactInformation = 'https://admin-shell.io/zvei/nameplate/1/0/ContactInformations/ContactInformation',
    Document = 'https://admin-shell.io/vdi/2770/1/0/Document',
    ProductCarbonFootprint = '0173-1#01-AHE716#001',
    TransportCarbonFootprint = '0173-1#01-AHE717#001',
    PCFLiveCyclePhase = '0173-1#02-ABG858#001',
    PCFQuantityOfMeasureForCalculation = '0173-1#02-ABG857#001',
    PCFReferenceValueForCalculation = '0173-1#02-ABG856#001',
    PCFCO2eq = '0173-1#02-ABG855#001',
    PCFCalculationMethod = '0173-1#02-ABG854#001',
    PCFGoodsAddressHandover = '0173-1#02-ABI497#001',
    PCFAddressStreet = '0173-1#02-ABH956#001',
    PCFAddressHouseNumber = '0173-1#02-ABH957#001',
    PCFAddressZipCode = '0173-1#02-ABH958#001',
    PCFAddressCityTown = '0173-1#02-ABH959#001',
    PCFAddressCountry = '0173-1#02-AAO259#005',
    PCFAddressLatitude = '0173-1#02-ABH960#001',
    PCFAddressLongitude = '0173-1#02-ABH961#001',
    TimeSeriesSegments = 'https://admin-shell.io/idta/TimeSeries/Segments/1/1',
    LinkedSegment = 'https://admin-shell.io/idta/TimeSeries/Segments/LinkedSegment/1/1',
    TimeSeriesSegmentName = 'https://admin-shell.io/idta/TimeSeries/Segment/Name/1/1',
    TimeSeriesSegmentDescription = 'https://admin-shell.io/idta/TimeSeries/Segment/Description/1/1',
    TimeSeriesLinkedSegmentEndpoint = 'https://admin-shell.io/idta/TimeSeries/Endpoint/1/1',
    TimeSeriesLinkedSegmentQuery = 'https://admin-shell.io/idta/TimeSeries/Query/1/1',
    EntryNode = 'https://admin-shell.io/idta/HierarchicalStructures/EntryNode/1/0',
    BulkCount = 'https://admin-shell.io/idta/HierarchicalStructures/BulkCount/1/0',
}
