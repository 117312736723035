import { AppBar, Box, IconButton, styled, Toolbar, Typography } from '@mui/material';
import { XitasoLogo } from '../components/basics/XitasoLogo';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import MainMenu from '../components/composits/menu/MainMenu';
import { useAuth } from 'hooks/UseAuth';
import { useIsTablet } from 'hooks/UseBreakpoints';
import { messages } from '../i18n/localization';
import { FormattedMessage } from 'react-intl';

const Offset = styled(Box)(({ theme }) => theme.mixins.toolbar);

const StyledLogoWrapper = styled(Box)(() => ({
    '.logo': {
        // toolbar min-height is 56px
        height: 32,
        margin: 10,
        '@media(min-width:600px)': {
            // toolbar min-height is 64px
            height: 36,
        },
    },
}));

const StyledToolbar = styled(Toolbar)(() => ({
    backgroundColor: '#999999',
    '&.hidden': {
        minHeight: 0,
        height: 0,
        overflow: 'hidden',
    },
}));

export function Header() {
    const auth = useAuth();
    const isTablet = useIsTablet();

    return (
        <>
            <AppBar position="fixed">
                <StyledToolbar disableGutters className={auth.isLoggedIn && !isTablet ? 'hidden' : ''}>
                    <MainMenu />
                    <StyledLogoWrapper display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                        <Box className="logo">
                            <XitasoLogo />
                        </Box>
                    </StyledLogoWrapper>
                    <Typography>
                        <FormattedMessage {...messages.mnestix.welcome} />
                    </Typography>
                    <IconButton edge="end" color="inherit" sx={{ m: 1 }}>
                        <QrCodeScannerIcon />
                    </IconButton>
                </StyledToolbar>
            </AppBar>
            <Offset />
        </>
    );
}
