/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { AASOverviewCard } from '../composits/aas-viewer/AASOverviewCard';
import { SubmodelsOverviewCard } from '../composits/aas-viewer/SubmodelsOverviewCard';
import { useCurrentAasContext } from 'contexts/CurrentAasContext';
import { useNotificationSpawner } from 'hooks/UseNotificationSpawner';
import { FormattedMessage } from 'react-intl';
import { messages } from 'i18n/localization';
import { decodeBase64 } from 'util/Base64Util';
import { ArrowForward } from '@mui/icons-material';
import { repositoryClient_v3 } from 'api';
import { showError } from 'util/ErrorHandlerUtil';
import { Reference } from 'api/v3/aas_core_meta/types';

export function AASView() {
    const { base64AasId } = useParams();
    const [aas, setAas] = useCurrentAasContext();
    const [submodels, setSubmodels] = useState<Reference[]>();
    const [productImage, setProductImage] = useState<string>();
    const [isLoadingAas, setIsLoadingAas] = useState(false);
    const [isLoadingSubmodels, setIsLoadingSubmodels] = useState(false);
    const [hasImage, setHasImage] = useState(true);
    const notificationSpawner = useNotificationSpawner();

    // TODO: get submodels here to set product image on AASOverviewCard
    useEffect(() => {
        async function _fetchAas() {
            try {
                setIsLoadingAas(true);
                const shell = await repositoryClient_v3.getAssetAdministrationShellById(base64AasId as string);
                setAas(shell);
                setSubmodels(shell.submodels ?? undefined);
                const productImageString = shell?.assetInformation?.defaultThumbnail?.path ?? '';

                if (productImageString) {
                    setProductImage(productImageString.replace(/^file:\/\//, 'https://'));
                } else {
                    setHasImage(false);
                    setProductImage('');
                }
            } catch (e) {
                showError(e, notificationSpawner);
            } finally {
                setIsLoadingAas(false);
            }
        }
        _fetchAas();
    }, [base64AasId]);

    const pageStyles = {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        alignItems: 'center',
        width: '100%',
        marginBottom: '50px',
        marginTop: '20px',
    };

    const viewerStyles = {
        maxWidth: '1125px',
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    };

    return (
        <Box sx={pageStyles}>
            {aas || isLoadingAas || isLoadingSubmodels ? (
                <>
                    <Typography
                        variant="h2"
                        style={{
                            width: '90%',
                            margin: '0 auto',
                            marginTop: '10px',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            textAlign: 'center',
                            display: 'inline-block',
                        }}
                    >
                        {isLoadingAas ? <Skeleton width="40%" sx={{ margin: '0 auto' }} /> : aas?.displayName?.[0].text}
                    </Typography>
                    <Box sx={viewerStyles}>
                        <AASOverviewCard
                            aas={aas}
                            productImage={productImage}
                            isLoading={isLoadingAas}
                            hasImage={hasImage}
                        />
                        <SubmodelsOverviewCard smReferences={submodels} isLoading={isLoadingSubmodels} />
                    </Box>
                </>
            ) : (
                <>
                    <Typography
                        variant="h2"
                        style={{
                            width: '90%',
                            margin: '0 auto',
                            marginTop: '10px',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            textAlign: 'center',
                            display: 'inline-block',
                        }}
                    >
                        <FormattedMessage {...messages.mnestix.noDataFound} />
                    </Typography>
                    <Typography color="text.secondary">
                        <FormattedMessage
                            {...messages.mnestix.noDataFoundFor}
                            values={{ name: decodeBase64(base64AasId as string) }}
                        />
                    </Typography>
                    <Button variant="contained" startIcon={<ArrowForward />} href="/">
                        <FormattedMessage {...messages.mnestix.toHome} />
                    </Button>
                </>
            )}
        </Box>
    );
}
