import { Typography } from '@mui/material';
import { getTranslationText } from 'util/SubmodelResolverUtil';
import { useIntl } from 'react-intl';
import { MultiLanguageProperty } from 'api/v3/aas_core_meta/types';

type MultiLanguagePropertyComponentProps = {
    readonly mLangProp: MultiLanguageProperty;
};

export function MultiLanguagePropertyComponent(props: MultiLanguagePropertyComponentProps) {
    const { mLangProp } = props;
    const intl = useIntl();
    return <Typography>{getTranslationText(mLangProp, intl) || '-'}</Typography>;
}
