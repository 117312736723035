import { Submodel } from 'api/v3/aas_core_meta/types'
import { encodeBase64 } from 'util/Base64Util';

export class ConfigurationShellApi {
    basePath: string;
    constructor(protected _basePath: string = '') {
        this.basePath = _basePath
    }
    
    public async getIdGenerationSettings(bearerToken: string): Promise<Submodel> {
        const submodelReferences = await this.getSubmodelReference(bearerToken);
        const submodelIdEncoded = encodeBase64(submodelReferences.result[0].keys[0]?.value);
        
        return fetch("submodel/default/" + submodelIdEncoded, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': bearerToken
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response;
            }
        })
    }
    
    
    private async getSubmodelReference(bearerToken: string): Promise<any> {
        return fetch(this.basePath, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': bearerToken
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response;
            }
        })
    }

    public putSingleIdGenerationSetting(idShort: string, bearerToken: string, values: { prefix: string, dynamicPart: string}): Promise<(void | Response)[]> {
        const promises: Promise<void | Response>[] = [
            this.putSingleIdGenerationSettingValue(`${idShort}.Prefix`, bearerToken, values.prefix),
            this.putSingleIdGenerationSettingValue(`${idShort}.DynamicPart`, bearerToken, values.dynamicPart),
        ];

        return Promise.all(promises);
    }

    protected async putSingleIdGenerationSettingValue(path: string, bearerToken: string, value: string): Promise<void | Response> {
        // TODO-424: add this path to reverse proxy routes to don't have a hardcoded submodelId anymore
        const response = await fetch('submodel/default/aHR0cHM6Ly9yZXBvZG9tYWludXJsLmNvbS9zbS9CNDYxQzZFRDMyMjE0OTMzQjhCNkNFNTY5QzhGMEEwMy8xLzA/submodel-elements/' + path + '/$value', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            },
            body: '"' + value + '"'
        });
        
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            throw response;
        }
    }
}
