import { Box, Link, styled, Typography } from '@mui/material';
import { File } from 'api/v3/aas_core_meta/types';
import { messages } from 'i18n/localization';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { getSanitizedHref } from 'util/HrefUtil';

const StyledFileImg = styled('img')(() => ({
    objectFit: 'contain',
    objectPosition: 'left top',
    maxWidth: '100%',
    maxHeight: '300px',
}));

type FileComponentProps = {
    readonly file: File;
};

export function FileComponent(props: FileComponentProps) {
    const { file } = props;
    if (!file) {
        return <></>;
    }

    function getRenderElement(): ReactNode {
        // TODO: Handle PDFs and such
        if (file.contentType?.startsWith('image')) {
            return (
                <Box>
                    <StyledFileImg src={file.value} />
                </Box>
            );
        }
        return file.value ? (
            <Link href={getSanitizedHref(file.value?.toString())} target="_blank">
                <Typography>{file.value?.toString()}</Typography>
            </Link>
        ) : (
            <Typography>
                <FormattedMessage {...messages.mnestix.notAvailable} />
            </Typography>
        );
    }
    return <>{getRenderElement()}</>;
}
