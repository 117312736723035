import { Box, Card, CardContent, Divider, Skeleton, Typography } from '@mui/material';
import { TabSelectorItem, VerticalTabSelector } from '../../basics/VerticalTabSelector';
import { useEffect, useState } from 'react';
import { useIsMobile } from 'hooks/UseBreakpoints';
import { MobileModal } from '../../basics/MobileModal';
import { messages } from 'i18n/localization';
import { FormattedMessage } from 'react-intl';
import { SubmodelDetail } from './submodel/SubmodelDetail';
import { Reference, Submodel } from 'api/v3/aas_core_meta/types';
import { submodelClient_v3 } from 'api';
import { useAsyncEffect } from 'hooks/UseAsyncEffect';
import { SubmodelSorting } from 'components/composits/aas-viewer/submodel/sorting/SubmodelSorting';

export type SubmodelsOverviewCardProps = { readonly smReferences?: Reference[]; readonly isLoading?: boolean };

function getSubmodelMetaData(submodelId: string): Promise<Submodel> {
    return submodelClient_v3.getSubmodelMetaDataById(submodelId);
}

export function SubmodelsOverviewCard(props: SubmodelsOverviewCardProps) {
    const [selectedItem, setSelectedItem] = useState<TabSelectorItem>();
    const [selectedSubmodel, setSelectedSubmodel] = useState<Submodel>();

    SubmodelSorting(selectedSubmodel);

    const [items, setItems] = useState<TabSelectorItem[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const firstSubmodelIdShort = 'Nameplate';

    useAsyncEffect(async () => {
        const tmp = props.smReferences?.flatMap((sm) => {
            return sm.keys.flatMap(async (key) => {
                const metadata = await getSubmodelMetaData(key.value);
                return { id: key.value, label: metadata.idShort ?? '', metadata };
            });
        });

        let awaitedSubmodules;

        if (tmp) {
            awaitedSubmodules = await Promise.all(tmp);
        }

        if (awaitedSubmodules) {
            awaitedSubmodules.sort(function (x, y) {
                return x.label == firstSubmodelIdShort ? -1 : y.label == firstSubmodelIdShort ? 1 : 0;
            });
            setItems(awaitedSubmodules);
        }
    }, [props.smReferences]);

    useEffect(() => {
        setOpen(!!selectedItem && isMobile);
    }, [isMobile, selectedItem]);

    useEffect(() => {
        setSelectedItem(isMobile ? undefined : items?.[0]);
    }, [isMobile, items]);

    useAsyncEffect(async () => {
        const selectedSubmodel = items?.find((el) => el.id === selectedItem?.id);
        let fetchedSubmodel;

        if (selectedSubmodel) {
            fetchedSubmodel = await submodelClient_v3.getSubmodelById(selectedSubmodel?.id ?? '');
        }

        if (fetchedSubmodel) {
            setSelectedSubmodel(fetchedSubmodel);
        }
    }, [selectedItem, props.smReferences]);

    const handleClose = () => {
        setOpen(false);
        setSelectedItem(undefined);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h3" marginBottom="15px">
                    <FormattedMessage {...messages.mnestix.submodels} />
                </Typography>
                <Box display="grid" gridTemplateColumns={isMobile ? '1fr' : '1fr 2fr'} gap="40px">
                    {props.isLoading && !props.smReferences ? (
                        <>
                            <Box>
                                {[0, 1, 2].map((i) => {
                                    return <Skeleton variant="rectangular" key={i} height={50} sx={{ mb: 2 }} />;
                                })}
                            </Box>
                            <Box>
                                {[0, 1, 2].map((i) => {
                                    return (
                                        <Box sx={{ mb: 2 }} key={i}>
                                            <Skeleton variant="text" width="50%" />
                                            <Skeleton variant="text" width="30%" />
                                            {i < 2 && <Divider sx={{ mt: 2 }} />}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </>
                    ) : (
                        <>
                            <VerticalTabSelector items={items} selected={selectedItem} setSelected={setSelectedItem} />
                            {isMobile ? (
                                <MobileModal
                                    title={items.find((i) => i.id === selectedItem?.id)?.label}
                                    open={open}
                                    handleClose={handleClose}
                                    content={<SubmodelDetail submodel={selectedSubmodel} />}
                                />
                            ) : (
                                <SubmodelDetail submodel={selectedSubmodel} />
                            )}
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}
