import { CheckCircle, CloudUploadOutlined, Delete, MoreVert, Restore } from '@mui/icons-material';
import {
    Box,
    Divider,
    Fade,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Skeleton,
    Typography,
} from '@mui/material';
import { templatesClient } from 'api';
import { Breadcrumbs } from 'components/basics/Breadcrumbs';
import { TemplateDeleteDialog } from 'components/composits/templates/TemplateDeleteDialog';
import { TemplateEditTree } from 'components/composits/templates/template-edit/TemplateEditTree';
import { useNotificationSpawner } from 'hooks/UseNotificationSpawner';
import { messages } from 'i18n/localization';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { showError } from 'util/ErrorHandlerUtil';
import { SubmodelViewObject } from 'types/SubmodelViewObject';
import { deleteItem, generateSubmodel, generateSubmodelViewObject, rewriteNodeIds } from 'util/SubmodelViewObjectUtil';
import {
    TemplateEditFields,
    TemplateEditFieldsProps,
} from 'components/composits/templates/template-edit/TemplateEditFields';
import { useAuth } from 'hooks/UseAuth';
import { LoadingButton } from '@mui/lab';
import cloneDeep from 'lodash/cloneDeep';
import { Qualifier, Submodel } from 'api/v3/aas_core_meta/types';
import { useApis } from '../azureAuthentication/ApiProvider';

export function TemplateEditView() {
    const { id } = useParams();
    const [localFrontendTemplate, setLocalFrontendTemplate] = useState<SubmodelViewObject | undefined>();
    const [templateDisplayName, setTemplateDisplayName] = useState<string | null>();
    const notificationSpawner = useNotificationSpawner();
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [wasRecentlySaved, setWasRecentlySaved] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editFieldsProps, setEditFieldsProps] = useState<TemplateEditFieldsProps>();
    const navigate = useNavigate();
    const { templateClientWithAuth } = useApis();
    const auth = useAuth();
    const bearerToken = auth.getBearerToken();
    const [deletedItems, setDeletedItems] = useState<string[]>([]);
    const [defaultTemplates, setDefaultTemplates] = useState<Submodel[]>();

    const fetchCustom = async () => {
        if (!id) return;
        const custom = await templatesClient.getCustom(bearerToken, id);
        setLocalFrontendTemplate(generateSubmodelViewObject(custom));
    };

    const fetchDefaultTemplates = async () => {
        const defaultTemplates = await templatesClient.getDefaults(bearerToken);
        setDefaultTemplates(defaultTemplates);
    };

    useEffect(() => {
        if (bearerToken != '') {
            const _fetchCustom = async () => {
                try {
                    setIsLoading(true);
                    await fetchDefaultTemplates();
                    await fetchCustom();
                } catch (e) {
                    showError(e, notificationSpawner);
                } finally {
                    setIsLoading(false);
                }
            };
            _fetchCustom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, bearerToken]);

    useEffect(() => {
        // get displayName out of Qualifiers or use idShort of Submodel
        setTemplateDisplayName(
            localFrontendTemplate?.data?.qualifiers?.find((q: Qualifier) => {
                return q.type === 'displayName';
            })?.value || localFrontendTemplate?.data?.idShort,
        );
    }, [localFrontendTemplate]);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        handleMenuClose();
        openDialog();
    };

    const handleRevertClick = async () => {
        try {
            setIsLoading(true);
            await fetchCustom();
            setChangesMade(false);
            handleMenuClose();
        } catch (e) {
            showError(e, notificationSpawner);
        } finally {
            setIsLoading(false);
        }
    };
    const openDialog = () => {
        setDeleteDialogOpen(true);
    };

    const closeDialog = () => {
        setDeleteDialogOpen(false);
    };

    // TODO: reuse method from TemplateView
    const deleteTemplate = async () => {
        if (!id) return;
        try {
            await templatesClient.deleteCustomById(bearerToken, id);
            notificationSpawner.spawn({
                message: intl.formatMessage(messages.mnestix.templateDeletedSuccessfully),
                severity: 'success',
            });
            navigate('/templates');
        } catch (e) {
            showError(e, notificationSpawner);
        }
    };

    const onSelectionChange = (treePart: SubmodelViewObject, onTreePartChange: (tree: SubmodelViewObject) => void) => {
        if (editFieldsProps?.templatePart?.id !== treePart.id) {
            setEditFieldsProps({ templatePart: treePart, onTemplatePartChange: onTreePartChange, updateTemplatePart });
        }
    };

    const onTemplateChange = (template: SubmodelViewObject, deletedItemIds?: string[]) => {
        setChangesMade(true);
        setLocalFrontendTemplate(template);
        if (deletedItemIds) {
            setDeletedItems(deletedItemIds);
        }
        //Update about to be deleted here
        if (editFieldsProps?.templatePart?.id) {
            if (deletedItemIds?.includes(editFieldsProps?.templatePart?.id)) {
                const newTemplatePart = cloneDeep(editFieldsProps.templatePart);
                newTemplatePart.isAboutToBeDeleted = true;
                setEditFieldsProps({ ...editFieldsProps, templatePart: newTemplatePart });
            } else if (editFieldsProps.templatePart.isAboutToBeDeleted) {
                const newTemplatePart = cloneDeep(editFieldsProps.templatePart);
                newTemplatePart.isAboutToBeDeleted = false;
                setEditFieldsProps({ ...editFieldsProps, templatePart: newTemplatePart });
            }
        }
    };

    const updateTemplatePart = (templatePart: SubmodelViewObject, onChange: (obj: SubmodelViewObject) => void) => {
        setEditFieldsProps({ ...editFieldsProps, templatePart, onTemplatePartChange: onChange, updateTemplatePart });
    };

    const onSaveChanges = async () => {
        let updatedTemplate;
        if (deletedItems) {
            updatedTemplate = deleteElements();
            setDeletedItems([]);
        } else {
            updatedTemplate = localFrontendTemplate;
        }
        if (updatedTemplate) {
            try {
                setIsSaving(true);
                const submodel = generateSubmodel(updatedTemplate);
                await templateClientWithAuth.updateCustomSubmodel(submodel, submodel.id);
                handleSuccessfullSave();
                setLocalFrontendTemplate(updatedTemplate);
            } catch (e) {
                showError(e, notificationSpawner);
            } finally {
                setIsSaving(false);
            }
        }
    };

    function deleteElements() {
        if (localFrontendTemplate) {
            let newLocalFrontendTemplate = cloneDeep(localFrontendTemplate);
            for (const nodeId of deletedItems) {
                newLocalFrontendTemplate = deleteItem(nodeId, newLocalFrontendTemplate);
            }
            rewriteNodeIds(newLocalFrontendTemplate, '0');
            return newLocalFrontendTemplate;
        }
        return undefined;
    }

    const handleSuccessfullSave = () => {
        notificationSpawner.spawn({
            severity: 'success',
            message: intl.formatMessage(messages.mnestix.changesSavedSuccessfully),
        });
        setChangesMade(false);
        setWasRecentlySaved(true);
        setTimeout(() => {
            setWasRecentlySaved(false);
        }, 3000);
    };

    function isCustomTemplate(template: SubmodelViewObject | undefined): boolean | undefined {
        let returnValue: boolean | undefined;
        if (template) {
            const id = template.data?.semanticId?.keys?.[0]?.value;
            if (id && defaultTemplates) {
                returnValue = true;
                for (const d of defaultTemplates) {
                    if (id == d.semanticId?.keys?.[0]?.value) {
                        returnValue = false;
                    }
                }
            }
        }
        return returnValue;
    }

    return (
        <Box sx={{ p: 3, maxWidth: '1125px', width: '100%', margin: '0 auto' }}>
            <Breadcrumbs
                links={[
                    {
                        label: intl.formatMessage(messages.mnestix.templates),
                        path: '/templates',
                    },
                ]}
            />
            <Box sx={{ display: 'flex', mb: 3 }}>
                <Box sx={{ minWidth: '50%' }}>
                    <Typography variant="h2" color="primary" sx={{ my: 1 }}>
                        {/* TODO: Make this editable as an input field */}
                        {isLoading ? <Skeleton width="40%" /> : templateDisplayName || ''}
                    </Typography>
                    <Typography color="text.secondary">
                        {isLoading ? (
                            <Skeleton width="60%" />
                        ) : (
                            !!localFrontendTemplate?.data?.semanticId?.keys?.[0]?.value &&
                            localFrontendTemplate.data?.semanticId.keys[0].value
                        )}
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Fade in={wasRecentlySaved} timeout={500}>
                            <CheckCircle color="success" sx={{ mr: 1 }} />
                        </Fade>
                        <LoadingButton
                            variant="contained"
                            startIcon={<CloudUploadOutlined />}
                            disabled={!changesMade}
                            loading={isSaving}
                            onClick={onSaveChanges}
                        >
                            <FormattedMessage {...messages.mnestix.saveChanges} />
                        </LoadingButton>
                        <IconButton sx={{ ml: 1 }} onClick={handleMenuClick} className="more-button">
                            <MoreVert />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
                            <MenuItem onClick={handleRevertClick} disabled={!changesMade}>
                                <ListItemIcon>
                                    <Restore fontSize="small" />
                                </ListItemIcon>
                                <FormattedMessage {...messages.mnestix.revertChanges} />
                            </MenuItem>
                            <MenuItem onClick={handleDeleteClick}>
                                <ListItemIcon>
                                    <Delete fontSize="small" />
                                </ListItemIcon>
                                <FormattedMessage {...messages.mnestix.delete} />
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </Box>
            <Paper sx={{ display: 'flex', maxHeight: 'calc(100vh - 220px)', overflow: 'hidden' }}>
                <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
                    {isLoading ? (
                        <>
                            <Skeleton variant="text" width="50%" />
                            {[0, 1, 2].map((i) => (
                                <Skeleton key={i} variant="text" width="70%" sx={{ ml: 4, my: 2 }} />
                            ))}
                        </>
                    ) : (
                        <TemplateEditTree
                            rootTree={localFrontendTemplate}
                            onTreeChange={onTemplateChange}
                            onSelectionChange={onSelectionChange}
                        />
                    )}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
                    <TemplateEditFields
                        {...editFieldsProps}
                        isCustomTemplate={isCustomTemplate(localFrontendTemplate)}
                    />
                </Box>
            </Paper>
            <TemplateDeleteDialog
                open={deleteDialogOpen}
                onClose={closeDialog}
                onDelete={() => deleteTemplate()}
                itemName={templateDisplayName ?? null}
            />
        </Box>
    );
}
