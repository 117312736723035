import { InfoOutlined, InsertDriveFileOutlined, OpenInNew } from '@mui/icons-material';
import { Box, Button, IconButton, Link, styled, Typography } from '@mui/material';
import {
    MultiLanguageProperty,
    Property,
    ISubmodelElement,
    SubmodelElementCollection,
    File,
} from 'api/v3/aas_core_meta/types';
import { DataRow } from 'components/basics/DataRow';
import { PdfDocumentIcon } from 'components/custom-icons/PdfDocumentIcon';
import { messages } from 'i18n/localization';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTranslationText, hasSemanticId } from 'util/SubmodelResolverUtil';
import { DocumentDetailsDialog } from './DocumentDetailsDialog';

enum DocumentSpecificSemanticId {
    DocumentVersion = 'https://admin-shell.io/vdi/2770/1/0/DocumentVersion',
    Title = 'https://admin-shell.io/vdi/2770/1/0/DocumentDescription/Title',
    File = 'https://admin-shell.io/vdi/2770/1/0/StoredDocumentRepresentation/DigitalFile',
    PreviewFile = 'https://admin-shell.io/vdi/2770/1/0/StoredDocumentRepresentation/PreviewFile',
    OrganizationName = 'https://admin-shell.io/vdi/2770/1/0/Organization/OrganizationName',
}

type MarkingsComponentProps = {
    readonly submodelElement?: SubmodelElementCollection;
    readonly hasDivider?: boolean;
};

type FileViewObject = {
    mimeType: string;
    title: string;
    url: string;
    previewImgUrl: string;
    organizationName: string;
};

const StyledImageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    height: 90,
    boxShadow: theme.shadows['3'],
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    img: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    '.MuiSvgIcon-root': {
        fontSize: '2.5rem',
        opacity: '.5',
    },
}));

export function DocumentComponent(props: MarkingsComponentProps) {
    const intl = useIntl();
    const [fileViewObject, setFileViewObject] = useState(getFileViewObject());
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    useEffect(() => {
        setFileViewObject(getFileViewObject());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.submodelElement]);

    const handleDetailsClick = () => {
        setDetailsModalOpen(true);
    };

    const handleDetailsModalClose = () => {
        setDetailsModalOpen(false);
    };

    function getFileViewObject(): FileViewObject {
        let mimeType = '';
        let url = '';
        let title = props.submodelElement?.idShort || '';
        let previewImgUrl = '';
        let organizationName = '';
        if (props.submodelElement?.value) {
            props.submodelElement.value.forEach((smEl: ISubmodelElement) => {
                // check for DocumentVersions
                if (hasSemanticId(smEl, DocumentSpecificSemanticId.DocumentVersion)) {
                    const coll = smEl as SubmodelElementCollection;
                    if (coll.value) {
                        for (const versionEl of Array.isArray(coll.value) ? coll.value : Object.values(coll.value)) {
                            const versionSubmodelEl = versionEl as ISubmodelElement;
                            // title
                            if (hasSemanticId(versionSubmodelEl, DocumentSpecificSemanticId.Title)) {
                                title = getTranslationText(versionSubmodelEl as MultiLanguageProperty, intl);
                                continue;
                            }
                            // file
                            if (hasSemanticId(versionSubmodelEl, DocumentSpecificSemanticId.File)) {
                                url = (versionSubmodelEl as File).value || '';
                                mimeType = (versionSubmodelEl as File).contentType;
                                continue;
                            }
                            // preview
                            if (hasSemanticId(versionSubmodelEl, DocumentSpecificSemanticId.PreviewFile)) {
                                previewImgUrl = (versionSubmodelEl as File).value || '';
                                continue;
                            }
                            // organization name
                            if (hasSemanticId(versionSubmodelEl, DocumentSpecificSemanticId.OrganizationName)) {
                                organizationName = (versionSubmodelEl as Property).value || '';
                            }
                        }
                    }
                }
            });
        }
        return {
            mimeType,
            url,
            title,
            previewImgUrl,
            organizationName,
        };
    }
    return (
        <DataRow title={props.submodelElement?.idShort} hasDivider={props.hasDivider}>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                <Box display="flex">
                    <Link href={fileViewObject.url} target="_blank">
                        <StyledImageWrapper>
                            {fileViewObject.previewImgUrl ? (
                                <img src={fileViewObject.previewImgUrl} />
                            ) : fileViewObject.mimeType === 'application/pdf' ? (
                                <PdfDocumentIcon color="primary" />
                            ) : (
                                <InsertDriveFileOutlined color="primary" />
                            )}
                        </StyledImageWrapper>
                    </Link>
                    <Box>
                        <Typography>{fileViewObject.title}</Typography>
                        {fileViewObject.organizationName && (
                            <Typography variant="body2" color="text.secondary">
                                {fileViewObject.organizationName}
                            </Typography>
                        )}
                        <Button
                            variant="outlined"
                            startIcon={<OpenInNew />}
                            sx={{ mt: 1 }}
                            href={fileViewObject.url}
                            target="_blank"
                        >
                            <FormattedMessage {...messages.mnestix.open} />
                        </Button>
                    </Box>
                </Box>
                <IconButton onClick={() => handleDetailsClick()} sx={{ ml: 1 }}>
                    <InfoOutlined />
                </IconButton>
            </Box>
            <DocumentDetailsDialog
                open={detailsModalOpen}
                handleClose={() => handleDetailsModalClose()}
                document={props.submodelElement as SubmodelElementCollection}
            />
        </DataRow>
    );
}
