/// <reference path="./custom.d.ts" />
// eslint:disable
// tslint:disable
/**
 * BaSyx Asset Administration Shell Repository HTTP REST-API
 * The full description of the generic BaSyx Asset Administration Shell Repository HTTP REST-API
 *
 * OpenAPI spec version: v1
 * Contact: constantin.ziesche@bosch.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 * 
 * NOTE: I actually edited this file manually...
 * Search for '* Manually' and 'TODO' to find all manual changes
 * 
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";
import { Asset } from './manual-supplements';

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccessControl
 */
export interface AccessControl {
    /**
     * 
     * @type {Reference}
     * @memberof AccessControl
     */
    selectableSubjectAttributes?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof AccessControl
     */
    defaultSubjectAttributes?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof AccessControl
     */
    selectablePermissions?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof AccessControl
     */
    defaultPermissions?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof AccessControl
     */
    selectableEnvironmentAttributes?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof AccessControl
     */
    defaultEnvironmentAttributes?: Reference;
    /**
     * 
     * @type {Array<AccessPermissionRule>}
     * @memberof AccessControl
     */
    accessPermissionRule?: Array<AccessPermissionRule>;
}
/**
 * 
 * @export
 * @interface AccessControlPolicyPoints
 */
export interface AccessControlPolicyPoints {
    /**
     * 
     * @type {PolicyAdministrationPoint}
     * @memberof AccessControlPolicyPoints
     */
    policyAdministrationPoint: PolicyAdministrationPoint;
    /**
     * 
     * @type {PolicyDecisionPoint}
     * @memberof AccessControlPolicyPoints
     */
    policyDecisionPoint: PolicyDecisionPoint;
    /**
     * 
     * @type {PolicyEnforcementPoint}
     * @memberof AccessControlPolicyPoints
     */
    policyEnforcementPoint: PolicyEnforcementPoint;
    /**
     * 
     * @type {PolicyInformationPoints}
     * @memberof AccessControlPolicyPoints
     */
    policyInformationPoints?: PolicyInformationPoints;
}
/**
 * 
 * @export
 * @interface AccessPermissionRule
 */
export interface AccessPermissionRule extends Referable {
    /**
     * 
     * @type {Array<Constraint>}
     * @memberof AccessPermissionRule
     */
    qualifiers?: Array<Constraint>;
    /**
     * 
     * @type {Array<SubjectAttributes>}
     * @memberof AccessPermissionRule
     */
    targetSubjectAttributes: Array<SubjectAttributes>;
    /**
     * 
     * @type {Array<PermissionsPerObject>}
     * @memberof AccessPermissionRule
     */
    permissionsPerObject?: Array<PermissionsPerObject>;
}
/**
 * 
 * @export
 * @interface AdministrativeInformation
 */
export interface AdministrativeInformation {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeInformation
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeInformation
     */
    revision?: string;
}
/**
 * 
 * @export
 * @interface AssetAdministrationShell
 */
export interface AssetAdministrationShell extends Identifiable {
    /**
     * 
     * @type {Array<EmbeddedDataSpecification>}
     * @memberof AssetAdministrationShell
     */
    embeddedDataSpecifications?: Array<EmbeddedDataSpecification>;
    /**
     * 
     * @type {Reference}
     * @memberof AssetAdministrationShell
     */
    derivedFrom?: Reference;
    /**
     * 
     * @type {Asset}
     * @memberof AssetAdministrationShell
     * Manually changed from 'Reference' to 'Asset'
     */
    asset: Asset;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof AssetAdministrationShell
     */
    submodels?: Array<Reference>;
    /**
     * 
     * @type {Array<View>}
     * @memberof AssetAdministrationShell
     */
    views?: Array<View>;
    /**
     * 
     * @type {Array<ConceptDictionary>}
     * @memberof AssetAdministrationShell
     */
    conceptDictionaries?: Array<ConceptDictionary>;
    /**
     * 
     * @type {Security}
     * @memberof AssetAdministrationShell
     */
    security?: Security;
}
/**
 * 
 * @export
 * @interface BasicEvent
 */
export interface BasicEvent extends Event {
    /**
     * 
     * @type {Reference}
     * @memberof BasicEvent
     */
    observed: Reference;
}
/**
 * 
 * @export
 * @interface Blob
 */
export interface Blob extends SubmodelElement {
    /**
     * 
     * @type {string}
     * @memberof Blob
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Blob
     */
    mimeType: string;
}
/**
 * 
 * @export
 * @interface BlobCertificate
 */
export interface BlobCertificate extends Certificate {
    /**
     * 
     * @type {Blob}
     * @memberof BlobCertificate
     */
    blobCertificate?: Blob;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof BlobCertificate
     */
    containedExtension?: Array<Reference>;
    /**
     * 
     * @type {boolean}
     * @memberof BlobCertificate
     */
    lastCertificate?: boolean;
}
/**
 * 
 * @export
 * @interface Capability
 */
export interface Capability extends SubmodelElement {
}
/**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate {
}
/**
 * 
 * @export
 * @interface ConceptDictionary
 */
export interface ConceptDictionary extends Referable {
    /**
     * 
     * @type {Array<EmbeddedDataSpecification>}
     * @memberof ConceptDictionary
     */
    embeddedDataSpecifications?: Array<EmbeddedDataSpecification>;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof ConceptDictionary
     */
    conceptDescriptions?: Array<Reference>;
}
/**
 * 
 * @export
 * @interface Constraint
 */
export interface Constraint {
    /**
     * 
     * @type {ModelType}
     * @memberof Constraint
     */
    modelType: ModelType;
}
/**
 * 
 * @export
 * @interface DataSpecificationContent
 */
export interface DataSpecificationContent {
}
/**
 * 
 * @export
 * @interface DataSpecificationIEC61360Content
 */
export interface DataSpecificationIEC61360Content extends ValueObject {
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360Content
     */
    dataType?: string;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationIEC61360Content
     */
    definition?: Array<LangString>;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationIEC61360Content
     */
    preferredName: Array<LangString>;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationIEC61360Content
     */
    shortName?: Array<LangString>;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360Content
     */
    sourceOfDefinition?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360Content
     */
    symbol?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360Content
     */
    unit?: string;
    /**
     * 
     * @type {Reference}
     * @memberof DataSpecificationIEC61360Content
     */
    unitId?: Reference;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360Content
     */
    valueFormat?: string;
    /**
     * 
     * @type {ValueList}
     * @memberof DataSpecificationIEC61360Content
     */
    valueList?: ValueList;
    /**
     * 
     * @type {Array<LevelType>}
     * @memberof DataSpecificationIEC61360Content
     */
    levelType?: Array<LevelType>;
}

/**
 * @export
 * @namespace DataSpecificationIEC61360Content
 */
export namespace DataSpecificationIEC61360Content {
}
/**
 * 
 * @export
 * @interface DataSpecificationPhysicalUnitContent
 */
export interface DataSpecificationPhysicalUnitContent {
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    unitName: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    unitSymbol: string;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    definition: Array<LangString>;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    siNotation?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    siName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    dinNotation?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    eceName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    eceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    nistName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    sourceOfDefinition?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    conversionFactor?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    registrationAuthorityId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationPhysicalUnitContent
     */
    supplier?: string;
}
/**
 * 
 * @export
 * @interface EmbeddedDataSpecification
 */
export interface EmbeddedDataSpecification {
    /**
     * 
     * @type {Reference}
     * @memberof EmbeddedDataSpecification
     */
    dataSpecification: Reference;
    /**
     * 
     * @type {DataSpecificationContent}
     * @memberof EmbeddedDataSpecification
     */
    dataSpecificationContent: DataSpecificationContent;
}
/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity extends SubmodelElement {
    /**
     * 
     * @type {Array<SubmodelElement>}
     * @memberof Entity
     */
    statements?: Array<SubmodelElement>;
    /**
     * 
     * @type {EntityType}
     * @memberof Entity
     */
    entityType: EntityType;
    /**
     * 
     * @type {Reference}
     * @memberof Entity
     */
    asset?: Reference;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EntityType {
    CoManagedEntity = <any> 'CoManagedEntity',
    SelfManagedEntity = <any> 'SelfManagedEntity'
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event extends SubmodelElement {
}
/**
 * 
 * @export
 * @interface HasDataSpecification
 */
export interface HasDataSpecification {
    /**
     * 
     * @type {Array<EmbeddedDataSpecification>}
     * @memberof HasDataSpecification
     */
    embeddedDataSpecifications?: Array<EmbeddedDataSpecification>;
}
/**
 * 
 * @export
 * @interface HasSemantics
 */
export interface HasSemantics {
    /**
     * 
     * @type {Reference}
     * @memberof HasSemantics
     */
    semanticId?: Reference;
}
/**
 * 
 * @export
 * @interface Identifiable
 */
export interface Identifiable extends Referable {
    /**
     * 
     * @type {Identifier}
     * @memberof Identifiable
     */
    identification: Identifier;
    /**
     * 
     * @type {AdministrativeInformation}
     * @memberof Identifiable
     */
    administration?: AdministrativeInformation;
}
/**
 * 
 * @export
 * @interface Identifier
 */
export interface Identifier {
    /**
     * 
     * @type {string}
     * @memberof Identifier
     */
    id: string;
    /**
     * 
     * @type {KeyType}
     * @memberof Identifier
     */
    idType: KeyType;
}
/**
 * 
 * @export
 * @interface InvocationRequest
 */
export interface InvocationRequest {
    /**
     * 
     * @type {string}
     * @memberof InvocationRequest
     */
    requestId?: string;
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof InvocationRequest
     */
    inoutputArguments?: Array<OperationVariable>;
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof InvocationRequest
     */
    inputArguments?: Array<OperationVariable>;
    /**
     * 
     * @type {number}
     * @memberof InvocationRequest
     */
    timeout?: number;
}
/**
 * 
 * @export
 * @interface InvocationResponse
 */
export interface InvocationResponse {
    /**
     * 
     * @type {string}
     * @memberof InvocationResponse
     */
    requestId?: string;
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof InvocationResponse
     */
    inoutputArguments?: Array<OperationVariable>;
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof InvocationResponse
     */
    outputArguments?: Array<OperationVariable>;
    /**
     * 
     * @type {Result}
     * @memberof InvocationResponse
     */
    operationResult?: Result;
    /**
     * 
     * @type {string}
     * @memberof InvocationResponse
     */
    executionState?: InvocationResponse.ExecutionStateEnum;
}

/**
 * @export
 * @namespace InvocationResponse
 */
export namespace InvocationResponse {
    /**
     * @export
     * @enum {string}
     */
    export enum ExecutionStateEnum {
        Initiated = <any> 'Initiated',
        Running = <any> 'Running',
        Completed = <any> 'Completed',
        Canceled = <any> 'Canceled',
        Failed = <any> 'Failed',
        Timeout = <any> 'Timeout'
    }
}
/**
 * 
 * @export
 * @interface Key
 */
export interface Key {
    /**
     * 
     * @type {KeyElements}
     * @memberof Key
     */
    type: KeyElements;
    /**
     * 
     * @type {KeyType}
     * @memberof Key
     */
    idType: KeyType;
    /**
     * 
     * @type {string}
     * @memberof Key
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof Key
     */
    local: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum KeyElements {
    Asset = <any> 'Asset',
    AssetAdministrationShell = <any> 'AssetAdministrationShell',
    ConceptDescription = <any> 'ConceptDescription',
    Submodel = <any> 'Submodel',
    AccessPermissionRule = <any> 'AccessPermissionRule',
    AnnotatedRelationshipElement = <any> 'AnnotatedRelationshipElement',
    BasicEvent = <any> 'BasicEvent',
    Blob = <any> 'Blob',
    Capability = <any> 'Capability',
    ConceptDictionary = <any> 'ConceptDictionary',
    DataElement = <any> 'DataElement',
    File = <any> 'File',
    Entity = <any> 'Entity',
    Event = <any> 'Event',
    MultiLanguageProperty = <any> 'MultiLanguageProperty',
    Operation = <any> 'Operation',
    Property = <any> 'Property',
    Range = <any> 'Range',
    ReferenceElement = <any> 'ReferenceElement',
    RelationshipElement = <any> 'RelationshipElement',
    SubmodelElement = <any> 'SubmodelElement',
    SubmodelElementCollection = <any> 'SubmodelElementCollection',
    View = <any> 'View',
    GlobalReference = <any> 'GlobalReference',
    FragmentReference = <any> 'FragmentReference'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum KeyType {
    Custom = <any> 'Custom',
    IRDI = <any> 'IRDI',
    IRI = <any> 'IRI',
    IdShort = <any> 'IdShort',
    FragmentId = <any> 'FragmentId'
}
/**
 * 
 * @export
 * @interface LangString
 */
export interface LangString {
    /**
     * 
     * @type {string}
     * @memberof LangString
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof LangString
     */
    text: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LevelType {
    Min = <any> 'Min',
    Max = <any> 'Max',
    Nom = <any> 'Nom',
    Typ = <any> 'Typ'
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    messageType?: Message.MessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text?: string;
}

/**
 * @export
 * @namespace Message
 */
export namespace Message {
    /**
     * @export
     * @enum {string}
     */
    export enum MessageTypeEnum {
        Unspecified = <any> 'Unspecified',
        Debug = <any> 'Debug',
        Information = <any> 'Information',
        Warning = <any> 'Warning',
        Error = <any> 'Error',
        Fatal = <any> 'Fatal',
        Exception = <any> 'Exception'
    }
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile extends SubmodelElement {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    mimeType: string;
}
/**
 * 
 * @export
 * @interface ModelType
 */
export interface ModelType {
    /**
     * 
     * @type {ModelTypes}
     * @memberof ModelType
     */
    name: ModelTypes;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ModelTypes {
    Asset = <any> 'Asset',
    AssetAdministrationShell = <any> 'AssetAdministrationShell',
    ConceptDescription = <any> 'ConceptDescription',
    Submodel = <any> 'Submodel',
    AccessPermissionRule = <any> 'AccessPermissionRule',
    AnnotatedRelationshipElement = <any> 'AnnotatedRelationshipElement',
    BasicEvent = <any> 'BasicEvent',
    Blob = <any> 'Blob',
    Capability = <any> 'Capability',
    ConceptDictionary = <any> 'ConceptDictionary',
    DataElement = <any> 'DataElement',
    File = <any> 'File',
    Entity = <any> 'Entity',
    Event = <any> 'Event',
    MultiLanguageProperty = <any> 'MultiLanguageProperty',
    Operation = <any> 'Operation',
    Property = <any> 'Property',
    Range = <any> 'Range',
    ReferenceElement = <any> 'ReferenceElement',
    RelationshipElement = <any> 'RelationshipElement',
    SubmodelElement = <any> 'SubmodelElement',
    SubmodelElementCollection = <any> 'SubmodelElementCollection',
    View = <any> 'View',
    GlobalReference = <any> 'GlobalReference',
    FragmentReference = <any> 'FragmentReference',
    Constraint = <any> 'Constraint',
    Formula = <any> 'Formula',
    Qualifier = <any> 'Qualifier'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ModelingKind {
    Template = <any> 'Template',
    Instance = <any> 'Instance'
}
/**
 * 
 * @export
 * @interface MultiLanguageProperty
 */
export interface MultiLanguageProperty extends SubmodelElement {
    /**
     * 
     * @type {Array<LangString> | { langString: Array<LangString> }}
     * @memberof MultiLanguageProperty
     * Manually added to allow working Package Explorer JSON export
     * Original was just Array<LangString>
     */
    value?: Array<LangString> | { langString: Array<LangString> };
    /**
     * 
     * @type {Reference}
     * @memberof MultiLanguageProperty
     */
    valueId?: Reference;
}
/**
 * 
 * @export
 * @interface ObjectAttributes
 */
export interface ObjectAttributes {
    /**
     * 
     * @type {Array<Property>}
     * @memberof ObjectAttributes
     */
    objectAttribute?: Array<Property>;
}
/**
 * 
 * @export
 * @interface Operation
 */
export interface Operation extends SubmodelElement {
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof Operation
     */
    inputVariable?: Array<OperationVariable>;
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof Operation
     */
    outputVariable?: Array<OperationVariable>;
    /**
     * 
     * @type {Array<OperationVariable>}
     * @memberof Operation
     */
    inoutputVariable?: Array<OperationVariable>;
}
/**
 * 
 * @export
 * @interface OperationVariable
 */
export interface OperationVariable {
    /**
     * 
     * @type {Blob | any | Capability | Entity | Event | BasicEvent | MultiLanguageProperty | Operation | Property | Range | ReferenceElement | RelationshipElement | SubmodelElementCollection}
     * @memberof OperationVariable
     */
    value: Blob | any | Capability | Entity | Event | BasicEvent | MultiLanguageProperty | Operation | Property | Range | ReferenceElement | RelationshipElement | SubmodelElementCollection;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {Reference}
     * @memberof Permission
     */
    permission: Reference;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    kindOfPermission: Permission.KindOfPermissionEnum;
}

/**
 * @export
 * @namespace Permission
 */
export namespace Permission {
    /**
     * @export
     * @enum {string}
     */
    export enum KindOfPermissionEnum {
        Allow = <any> 'Allow',
        Deny = <any> 'Deny',
        NotApplicable = <any> 'NotApplicable',
        Undefined = <any> 'Undefined'
    }
}
/**
 * 
 * @export
 * @interface PermissionsPerObject
 */
export interface PermissionsPerObject {
    /**
     * 
     * @type {Reference}
     * @memberof PermissionsPerObject
     */
    object?: Reference;
    /**
     * 
     * @type {ObjectAttributes}
     * @memberof PermissionsPerObject
     */
    targetObjectAttributes?: ObjectAttributes;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof PermissionsPerObject
     */
    permission?: Array<Permission>;
}
/**
 * 
 * @export
 * @interface PolicyAdministrationPoint
 */
export interface PolicyAdministrationPoint {
    /**
     * 
     * @type {AccessControl}
     * @memberof PolicyAdministrationPoint
     */
    localAccessControl?: AccessControl;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyAdministrationPoint
     */
    externalAccessControl: boolean;
}
/**
 * 
 * @export
 * @interface PolicyDecisionPoint
 */
export interface PolicyDecisionPoint {
    /**
     * 
     * @type {boolean}
     * @memberof PolicyDecisionPoint
     */
    externalPolicyDecisionPoints: boolean;
}
/**
 * 
 * @export
 * @interface PolicyEnforcementPoint
 */
export interface PolicyEnforcementPoint {
    /**
     * 
     * @type {boolean}
     * @memberof PolicyEnforcementPoint
     */
    externalPolicyEnforcementPoint: boolean;
}
/**
 * 
 * @export
 * @interface PolicyInformationPoints
 */
export interface PolicyInformationPoints {
    /**
     * 
     * @type {Array<Reference>}
     * @memberof PolicyInformationPoints
     */
    internalInformationPoint?: Array<Reference>;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyInformationPoints
     */
    externalInformationPoint: boolean;
}
/**
 * 
 * @export
 * @interface Property
 */
export interface Property extends SubmodelElement {
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    value?: string;
    /**
     * 
     * @type {Reference}
     * @memberof Property
     */
    valueId?: Reference;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
     // Manually extended because of Package Explorer export
    valueType?: Property.ValueTypeEnum | { dataObjectType: { name: Property.ValueTypeEnum}};
}

/**
 * @export
 * @namespace Property
 */
export namespace Property {
    /**
     * @export
     * @enum {string}
     */
    export enum ValueTypeEnum {
        AnyUri = <any> 'anyUri',
        Base64Binary = <any> 'base64Binary',
        Boolean = <any> 'boolean',
        Date = <any> 'date',
        DateTime = <any> 'dateTime',
        DateTimeStamp = <any> 'dateTimeStamp',
        Decimal = <any> 'decimal',
        Integer = <any> 'integer',
        Long = <any> 'long',
        Int = <any> 'int',
        Short = <any> 'short',
        Byte = <any> 'byte',
        NonNegativeInteger = <any> 'nonNegativeInteger',
        PositiveInteger = <any> 'positiveInteger',
        UnsignedLong = <any> 'unsignedLong',
        UnsignedInt = <any> 'unsignedInt',
        UnsignedShort = <any> 'unsignedShort',
        UnsignedByte = <any> 'unsignedByte',
        NonPositiveInteger = <any> 'nonPositiveInteger',
        NegativeInteger = <any> 'negativeInteger',
        Double = <any> 'double',
        Duration = <any> 'duration',
        DayTimeDuration = <any> 'dayTimeDuration',
        YearMonthDuration = <any> 'yearMonthDuration',
        Float = <any> 'float',
        GDay = <any> 'gDay',
        GMonth = <any> 'gMonth',
        GMonthDay = <any> 'gMonthDay',
        GYear = <any> 'gYear',
        GYearMonth = <any> 'gYearMonth',
        HexBinary = <any> 'hexBinary',
        NOTATION = <any> 'NOTATION',
        QName = <any> 'QName',
        String = <any> 'string',
        NormalizedString = <any> 'normalizedString',
        Token = <any> 'token',
        Language = <any> 'language',
        Name = <any> 'Name',
        NCName = <any> 'NCName',
        ENTITY = <any> 'ENTITY',
        ID = <any> 'ID',
        IDREF = <any> 'IDREF',
        NMTOKEN = <any> 'NMTOKEN',
        Time = <any> 'time'
    }
}
/**
 * 
 * @export
 * @interface Qualifiable
 */
export interface Qualifiable {
    /**
     * 
     * @type {Array<Constraint>}
     * @memberof Qualifiable
     */
    qualifiers?: Array<Constraint>;
}
/**
 * 
 * @export
 * @interface Range
 */
export interface Range extends SubmodelElement {
    /**
     * 
     * @type {string}
     * @memberof Range
     */
    // Manually extended because of Package Explorer export
    valueType: Range.ValueTypeEnum | { dataObjectType: { name: Range.ValueTypeEnum }};
    /**
     * 
     * @type {string}
     * @memberof Range
     */
    min: string;
    /**
     * 
     * @type {string}
     * @memberof Range
     */
    max: string;
}

/**
 * @export
 * @namespace Range
 */
export namespace Range {
    /**
     * @export
     * @enum {string}
     */
    export enum ValueTypeEnum {
        AnyUri = <any> 'anyUri',
        Base64Binary = <any> 'base64Binary',
        Boolean = <any> 'boolean',
        Date = <any> 'date',
        DateTime = <any> 'dateTime',
        DateTimeStamp = <any> 'dateTimeStamp',
        Decimal = <any> 'decimal',
        Integer = <any> 'integer',
        Long = <any> 'long',
        Int = <any> 'int',
        Short = <any> 'short',
        Byte = <any> 'byte',
        NonNegativeInteger = <any> 'nonNegativeInteger',
        PositiveInteger = <any> 'positiveInteger',
        UnsignedLong = <any> 'unsignedLong',
        UnsignedInt = <any> 'unsignedInt',
        UnsignedShort = <any> 'unsignedShort',
        UnsignedByte = <any> 'unsignedByte',
        NonPositiveInteger = <any> 'nonPositiveInteger',
        NegativeInteger = <any> 'negativeInteger',
        Double = <any> 'double',
        Duration = <any> 'duration',
        DayTimeDuration = <any> 'dayTimeDuration',
        YearMonthDuration = <any> 'yearMonthDuration',
        Float = <any> 'float',
        GDay = <any> 'gDay',
        GMonth = <any> 'gMonth',
        GMonthDay = <any> 'gMonthDay',
        GYear = <any> 'gYear',
        GYearMonth = <any> 'gYearMonth',
        HexBinary = <any> 'hexBinary',
        NOTATION = <any> 'NOTATION',
        QName = <any> 'QName',
        String = <any> 'string',
        NormalizedString = <any> 'normalizedString',
        Token = <any> 'token',
        Language = <any> 'language',
        Name = <any> 'Name',
        NCName = <any> 'NCName',
        ENTITY = <any> 'ENTITY',
        ID = <any> 'ID',
        IDREF = <any> 'IDREF',
        NMTOKEN = <any> 'NMTOKEN',
        Time = <any> 'time'
    }
}
/**
 * 
 * @export
 * @interface Referable
 */
export interface Referable {
    /**
     * 
     * @type {string}
     * @memberof Referable
     */
    idShort: string;
    /**
     * 
     * @type {string}
     * @memberof Referable
     */
    category?: string;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof Referable
     */
    description?: Array<LangString>;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof Referable
     * Manually added to allow working Package Explorer JSON export
     */
    descriptions?: Array<LangString>;
    /**
     * 
     * @type {Reference}
     * @memberof Referable
     */
    parent?: Reference;
    /**
     * 
     * @type {ModelType}
     * @memberof Referable
     */
    modelType: ModelType;
}
/**
 * 
 * @export
 * @interface Reference
 */
export interface Reference {
    /**
     * 
     * @type {Array<Key>}
     * @memberof Reference
     */
    keys: Array<Key>;
}
/**
 * 
 * @export
 * @interface ReferenceElement
 */
export interface ReferenceElement extends SubmodelElement {
    /**
     * 
     * @type {Reference}
     * @memberof ReferenceElement
     */
    value?: Reference;
}
/**
 * 
 * @export
 * @interface RelationshipElement
 */
export interface RelationshipElement extends SubmodelElement {
    /**
     * 
     * @type {Reference}
     * @memberof RelationshipElement
     */
    first: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof RelationshipElement
     */
    second: Reference;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    entityType?: string;
    /**
     * 
     * @type {any}
     * @memberof Result
     */
    entity?: any;
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    success?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    isException?: boolean;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Result
     */
    messages?: Array<Message>;
}
/**
 * 
 * @export
 * @interface Security
 */
export interface Security {
    /**
     * 
     * @type {AccessControlPolicyPoints}
     * @memberof Security
     */
    accessControlPolicyPoints: AccessControlPolicyPoints;
    /**
     * 
     * @type {Array<BlobCertificate>}
     * @memberof Security
     */
    certificate?: Array<BlobCertificate>;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof Security
     */
    requiredCertificateExtension?: Array<Reference>;
}
/**
 * 
 * @export
 * @interface SubjectAttributes
 */
export interface SubjectAttributes {
    /**
     * 
     * @type {Array<Reference>}
     * @memberof SubjectAttributes
     */
    subjectAttributes?: Array<Reference>;
}
/**
 * 
 * @export
 * @interface Submodel
 */
export interface Submodel extends Identifiable {
    /**
     * 
     * @type {Array<EmbeddedDataSpecification>}
     * @memberof Submodel
     */
    embeddedDataSpecifications?: Array<EmbeddedDataSpecification>;
    /**
     * 
     * @type {Array<Constraint>}
     * @memberof Submodel
     */
    qualifiers?: Array<Constraint>;
    /**
     * 
     * @type {Array<Constraint>}
     * @memberof SubmodelElement
     * Manually added to allow working Package Explorer JSON export
     */
         constraints?: Array<Constraint>;
    /**
     * 
     * @type {Reference}
     * @memberof Submodel
     */
    semanticId?: Reference;
    /**
     * 
     * @type {ModelingKind}
     * @memberof Submodel
     */
    kind?: ModelingKind;
    /**
     * 
     * @type {Array<SubmodelElement>}
     * @memberof Submodel
     */
    submodelElements?: Array<SubmodelElement>;
}
/**
 * 
 * @export
 * @interface SubmodelElement
 */
export interface SubmodelElement extends Referable {
    /**
     * 
     * @type {Array<EmbeddedDataSpecification>}
     * @memberof SubmodelElement
     */
    embeddedDataSpecifications?: Array<EmbeddedDataSpecification>;
    /**
     * 
     * @type {Reference}
     * @memberof SubmodelElement
     */
    semanticId?: Reference;
    /**
     * 
     * @type {Array<Constraint>}
     * @memberof SubmodelElement
     */
    qualifiers?: Array<Constraint>;
    /**
     * 
     * @type {Array<Constraint>}
     * @memberof SubmodelElement
     * Manually added to allow working Package Explorer JSON export
     */
    constraints?: Array<Constraint>;
    /**
     * 
     * @type {ModelingKind}
     * @memberof SubmodelElement
     */
    kind?: ModelingKind;
}
/**
 * 
 * @export
 * @interface SubmodelElementCollection
 */
export interface SubmodelElementCollection extends SubmodelElement {
    /**
     * 
     * @type { Array<Blob | any | Capability | Entity | Event | BasicEvent | MultiLanguageProperty | Operation | Property | Range | ReferenceElement | RelationshipElement | SubmodelElementCollection>}
     * @memberof SubmodelElementCollection
     */
    value?: Array<Blob | any | Capability | Entity | Event | BasicEvent | MultiLanguageProperty | Operation | Property | Range | ReferenceElement | RelationshipElement | SubmodelElementCollection>;
    /**
     * 
     * @type {boolean}
     * @memberof SubmodelElementCollection
     */
    allowDuplicates?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubmodelElementCollection
     */
    ordered?: boolean;
}

export interface SubModelElementCollectionContactInfo extends SubmodelElement {
    value?: string | {langString:Array<LangString>};
}
/**
 * 
 * @export
 * @interface ValueList
 */
export interface ValueList {
    /**
     * 
     * @type {Array<ValueReferencePairType>}
     * @memberof ValueList
     */
    valueReferencePairTypes: Array<ValueReferencePairType>;
}
/**
 * 
 * @export
 * @interface ValueObject
 */
export interface ValueObject {
    /**
     * 
     * @type {string}
     * @memberof ValueObject
     */
    value?: string;
    /**
     * 
     * @type {Reference}
     * @memberof ValueObject
     */
    valueId?: Reference;
    /**
     * 
     * @type {string}
     * @memberof ValueObject
     */
    // Manually extended because of Package Explorer export
    valueType?: ValueObject.ValueTypeEnum | { dataObjectType: { name: ValueObject.ValueTypeEnum }};
}

/**
 * @export
 * @namespace ValueObject
 */
export namespace ValueObject {
    /**
     * @export
     * @enum {string}
     */
    export enum ValueTypeEnum {
        AnyUri = <any> 'anyUri',
        Base64Binary = <any> 'base64Binary',
        Boolean = <any> 'boolean',
        Date = <any> 'date',
        DateTime = <any> 'dateTime',
        DateTimeStamp = <any> 'dateTimeStamp',
        Decimal = <any> 'decimal',
        Integer = <any> 'integer',
        Long = <any> 'long',
        Int = <any> 'int',
        Short = <any> 'short',
        Byte = <any> 'byte',
        NonNegativeInteger = <any> 'nonNegativeInteger',
        PositiveInteger = <any> 'positiveInteger',
        UnsignedLong = <any> 'unsignedLong',
        UnsignedInt = <any> 'unsignedInt',
        UnsignedShort = <any> 'unsignedShort',
        UnsignedByte = <any> 'unsignedByte',
        NonPositiveInteger = <any> 'nonPositiveInteger',
        NegativeInteger = <any> 'negativeInteger',
        Double = <any> 'double',
        Duration = <any> 'duration',
        DayTimeDuration = <any> 'dayTimeDuration',
        YearMonthDuration = <any> 'yearMonthDuration',
        Float = <any> 'float',
        GDay = <any> 'gDay',
        GMonth = <any> 'gMonth',
        GMonthDay = <any> 'gMonthDay',
        GYear = <any> 'gYear',
        GYearMonth = <any> 'gYearMonth',
        HexBinary = <any> 'hexBinary',
        NOTATION = <any> 'NOTATION',
        QName = <any> 'QName',
        String = <any> 'string',
        NormalizedString = <any> 'normalizedString',
        Token = <any> 'token',
        Language = <any> 'language',
        Name = <any> 'Name',
        NCName = <any> 'NCName',
        ENTITY = <any> 'ENTITY',
        ID = <any> 'ID',
        IDREF = <any> 'IDREF',
        NMTOKEN = <any> 'NMTOKEN',
        Time = <any> 'time'
    }
}
/**
 * 
 * @export
 * @interface ValueReferencePairType
 */
export interface ValueReferencePairType extends ValueObject {
}

/**
 * @export
 * @namespace ValueReferencePairType
 */
export namespace ValueReferencePairType {
}
/**
 * 
 * @export
 * @interface View
 */
export interface View extends Referable {
    /**
     * 
     * @type {Array<EmbeddedDataSpecification>}
     * @memberof View
     */
    embeddedDataSpecifications?: Array<EmbeddedDataSpecification>;
    /**
     * 
     * @type {Reference}
     * @memberof View
     */
    semanticId?: Reference;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof View
     */
    containedElements?: Array<Reference>;
}
/**
 * AssetAdministrationShellRepositoryApi - fetch parameter creator
 * @export
 */
export const AssetAdministrationShellRepositoryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a specific Asset Administration Shell at the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetAdministrationShellById(aasId: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling deleteAssetAdministrationShellById.');
            }
            const localVarPath = `/shells/{aasId}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves all Asset Administration Shells from the Asset Administration Shell repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssetAdministrationShells(options: any = {}): FetchArgs {
            const localVarPath = `/shells`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetAdministrationShellById(aasId: string, options: any = {}): FetchArgs {
            debugger;
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling getAssetAdministrationShellById.');
            }
            const localVarPath = `/shells/{aasId}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or updates a Asset Administration Shell at the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {AssetAdministrationShell} [body] The Asset Administration Shell
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssetAdministrationShell(aasId: string, body?: AssetAdministrationShell, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling putAssetAdministrationShell.');
            }
            const localVarPath = `/shells/{aasId}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AssetAdministrationShell" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoDeleteSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoDeleteSubmodelElementByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoDeleteSubmodelElementByIdShort.');
            }
            // verify required parameter 'seIdShortPath' is not null or undefined
            if (seIdShortPath === null || seIdShortPath === undefined) {
                throw new RequiredError('seIdShortPath','Required parameter seIdShortPath was null or undefined when calling shellRepoDeleteSubmodelElementByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{seIdShortPath}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"seIdShortPath"}}`, encodeURIComponent(String(seIdShortPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a specific Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoDeleteSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoDeleteSubmodelFromShellByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoDeleteSubmodelFromShellByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the result of an asynchronously started operation
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort Submodel&#x27;s short id
         * @param {string} idShortPathToOperation The IdShort path to the Operation
         * @param {string} requestId The request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetInvocationResultByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, requestId: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetInvocationResultByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoGetInvocationResultByIdShort.');
            }
            // verify required parameter 'idShortPathToOperation' is not null or undefined
            if (idShortPathToOperation === null || idShortPathToOperation === undefined) {
                throw new RequiredError('idShortPathToOperation','Required parameter idShortPathToOperation was null or undefined when calling shellRepoGetInvocationResultByIdShort.');
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling shellRepoGetInvocationResultByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{idShortPathToOperation}/invocationList/{requestId}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"idShortPathToOperation"}}`, encodeURIComponent(String(idShortPathToOperation)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetSubmodelElementByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoGetSubmodelElementByIdShort.');
            }
            // verify required parameter 'seIdShortPath' is not null or undefined
            if (seIdShortPath === null || seIdShortPath === undefined) {
                throw new RequiredError('seIdShortPath','Required parameter seIdShortPath was null or undefined when calling shellRepoGetSubmodelElementByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{seIdShortPath}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"seIdShortPath"}}`, encodeURIComponent(String(seIdShortPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the value of a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetSubmodelElementValueByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoGetSubmodelElementValueByIdShort.');
            }
            // verify required parameter 'seIdShortPath' is not null or undefined
            if (seIdShortPath === null || seIdShortPath === undefined) {
                throw new RequiredError('seIdShortPath','Required parameter seIdShortPath was null or undefined when calling shellRepoGetSubmodelElementValueByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{seIdShortPath}/value`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"seIdShortPath"}}`, encodeURIComponent(String(seIdShortPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves all Submodel-Elements from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElements(aasId: string, submodelIdShort: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetSubmodelElements.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoGetSubmodelElements.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetSubmodelFromShellByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoGetSubmodelFromShellByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the minimized version of a Submodel, i.e. only the values of SubmodelElements are serialized and returned
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelValues(aasId: string, submodelIdShort: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetSubmodelValues.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoGetSubmodelValues.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/values`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves all Submodels from the  Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelsFromShell(aasId: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoGetSubmodelsFromShell.');
            }
            const localVarPath = `/shells/{aasId}/submodels`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invokes a specific operation from the Submodel synchronously or asynchronously
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort Submodel&#x27;s short id
         * @param {string} idShortPathToOperation The IdShort path to the Operation
         * @param {InvocationRequest} [body] The parameterized request object for the invocation
         * @param {boolean} [async] Determines whether the execution of the operation is asynchronous (true) or not (false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoInvokeOperationByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, body?: InvocationRequest, async?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoInvokeOperationByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoInvokeOperationByIdShort.');
            }
            // verify required parameter 'idShortPathToOperation' is not null or undefined
            if (idShortPathToOperation === null || idShortPathToOperation === undefined) {
                throw new RequiredError('idShortPathToOperation','Required parameter idShortPathToOperation was null or undefined when calling shellRepoInvokeOperationByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{idShortPathToOperation}/invoke`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"idShortPathToOperation"}}`, encodeURIComponent(String(idShortPathToOperation)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvocationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or updates a Submodel-Element at the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {SubmodelElement} [body] The Submodel-Element object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelElement(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: SubmodelElement, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoPutSubmodelElement.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoPutSubmodelElement.');
            }
            // verify required parameter 'seIdShortPath' is not null or undefined
            if (seIdShortPath === null || seIdShortPath === undefined) {
                throw new RequiredError('seIdShortPath','Required parameter seIdShortPath was null or undefined when calling shellRepoPutSubmodelElement.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{seIdShortPath}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"seIdShortPath"}}`, encodeURIComponent(String(seIdShortPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubmodelElement" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Submodel-Element's value
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {any} [body] The new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: any, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoPutSubmodelElementValueByIdShort.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoPutSubmodelElementValueByIdShort.');
            }
            // verify required parameter 'seIdShortPath' is not null or undefined
            if (seIdShortPath === null || seIdShortPath === undefined) {
                throw new RequiredError('seIdShortPath','Required parameter seIdShortPath was null or undefined when calling shellRepoPutSubmodelElementValueByIdShort.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}/submodelElements/{seIdShortPath}/value`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)))
                .replace(`{${"seIdShortPath"}}`, encodeURIComponent(String(seIdShortPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or updates a Submodel to an existing Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {Submodel} [body] The serialized Submodel object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelToShell(aasId: string, submodelIdShort: string, body?: Submodel, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellRepoPutSubmodelToShell.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellRepoPutSubmodelToShell.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Submodel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId: string, submodelIdShort: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellsAasIdAasSubmodelsSubmodelIdShortGet.');
            }
            // verify required parameter 'submodelIdShort' is not null or undefined
            if (submodelIdShort === null || submodelIdShort === undefined) {
                throw new RequiredError('submodelIdShort','Required parameter submodelIdShort was null or undefined when calling shellsAasIdAasSubmodelsSubmodelIdShortGet.');
            }
            const localVarPath = `/shells/{aasId}/submodels/{submodelIdShort}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)))
                .replace(`{${"submodelIdShort"}}`, encodeURIComponent(String(submodelIdShort)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellsAasIdGet(aasId: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling shellsAasIdGet.');
            }
            const localVarPath = `/shells/{aasId}`
                .replace(`{${"aasId"}}`, encodeURIComponent(String(aasId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetAdministrationShellRepositoryApi - functional programming interface
 * @export
 */
export const AssetAdministrationShellRepositoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a specific Asset Administration Shell at the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetAdministrationShellById(aasId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).deleteAssetAdministrationShellById(aasId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves all Asset Administration Shells from the Asset Administration Shell repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssetAdministrationShells(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AssetAdministrationShell>> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).getAllAssetAdministrationShells(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetAdministrationShellById(aasId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssetAdministrationShell> {
            debugger;
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).getAssetAdministrationShellById(aasId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates or updates a Asset Administration Shell at the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {AssetAdministrationShell} [body] The Asset Administration Shell
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssetAdministrationShell(aasId: string, body?: AssetAdministrationShell, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssetAdministrationShell> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).putAssetAdministrationShell(aasId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoDeleteSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoDeleteSubmodelElementByIdShort(aasId, submodelIdShort, seIdShortPath, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a specific Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoDeleteSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoDeleteSubmodelFromShellByIdShort(aasId, submodelIdShort, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves the result of an asynchronously started operation
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort Submodel&#x27;s short id
         * @param {string} idShortPathToOperation The IdShort path to the Operation
         * @param {string} requestId The request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetInvocationResultByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, requestId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvocationResponse> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetInvocationResultByIdShort(aasId, submodelIdShort, idShortPathToOperation, requestId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmodelElement> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetSubmodelElementByIdShort(aasId, submodelIdShort, seIdShortPath, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves the value of a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetSubmodelElementValueByIdShort(aasId, submodelIdShort, seIdShortPath, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves all Submodel-Elements from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElements(aasId: string, submodelIdShort: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubmodelElement>> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetSubmodelElements(aasId, submodelIdShort, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves the Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Submodel> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetSubmodelFromShellByIdShort(aasId, submodelIdShort, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves the minimized version of a Submodel, i.e. only the values of SubmodelElements are serialized and returned
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelValues(aasId: string, submodelIdShort: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetSubmodelValues(aasId, submodelIdShort, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves all Submodels from the  Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelsFromShell(aasId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoGetSubmodelsFromShell(aasId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        // TODO: This is a workaround for a known BaSyx bug. Should be only:
                        // return response.json();
                        return response.json().then(resp => {
                            if(Array.isArray(resp)){
                                resp.forEach(sm => {
                                    if(sm.submodelElements && typeof sm.submodelElements === 'object') {
                                        sm.submodelElements = Object.values(sm.submodelElements);
                                    }
                                })
                            }
                            return resp;
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Invokes a specific operation from the Submodel synchronously or asynchronously
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort Submodel&#x27;s short id
         * @param {string} idShortPathToOperation The IdShort path to the Operation
         * @param {InvocationRequest} [body] The parameterized request object for the invocation
         * @param {boolean} [async] Determines whether the execution of the operation is asynchronous (true) or not (false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoInvokeOperationByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, body?: InvocationRequest, async?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoInvokeOperationByIdShort(aasId, submodelIdShort, idShortPathToOperation, body, async, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates or updates a Submodel-Element at the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {SubmodelElement} [body] The Submodel-Element object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelElement(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: SubmodelElement, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmodelElement> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoPutSubmodelElement(aasId, submodelIdShort, seIdShortPath, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Updates the Submodel-Element's value
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {any} [body] The new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoPutSubmodelElementValueByIdShort(aasId, submodelIdShort, seIdShortPath, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates or updates a Submodel to an existing Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {Submodel} [body] The serialized Submodel object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelToShell(aasId: string, submodelIdShort: string, body?: Submodel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Submodel> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellRepoPutSubmodelToShell(aasId, submodelIdShort, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves the Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId: string, submodelIdShort: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Submodel> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId, submodelIdShort, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellsAasIdGet(aasId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssetAdministrationShell> {
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).shellsAasIdGet(aasId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AssetAdministrationShellRepositoryApi - factory interface
 * @export
 */
export const AssetAdministrationShellRepositoryApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Deletes a specific Asset Administration Shell at the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetAdministrationShellById(aasId: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).deleteAssetAdministrationShellById(aasId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves all Asset Administration Shells from the Asset Administration Shell repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssetAdministrationShells(options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).getAllAssetAdministrationShells(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetAdministrationShellById(aasId: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).getAssetAdministrationShellById(aasId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates or updates a Asset Administration Shell at the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {AssetAdministrationShell} [body] The Asset Administration Shell
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssetAdministrationShell(aasId: string, body?: AssetAdministrationShell, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).putAssetAdministrationShell(aasId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoDeleteSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoDeleteSubmodelElementByIdShort(aasId, submodelIdShort, seIdShortPath, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a specific Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoDeleteSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoDeleteSubmodelFromShellByIdShort(aasId, submodelIdShort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves the result of an asynchronously started operation
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort Submodel&#x27;s short id
         * @param {string} idShortPathToOperation The IdShort path to the Operation
         * @param {string} requestId The request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetInvocationResultByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, requestId: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetInvocationResultByIdShort(aasId, submodelIdShort, idShortPathToOperation, requestId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetSubmodelElementByIdShort(aasId, submodelIdShort, seIdShortPath, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves the value of a specific Submodel-Element from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetSubmodelElementValueByIdShort(aasId, submodelIdShort, seIdShortPath, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves all Submodel-Elements from the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelElements(aasId: string, submodelIdShort: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetSubmodelElements(aasId, submodelIdShort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves the Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetSubmodelFromShellByIdShort(aasId, submodelIdShort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves the minimized version of a Submodel, i.e. only the values of SubmodelElements are serialized and returned
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelValues(aasId: string, submodelIdShort: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetSubmodelValues(aasId, submodelIdShort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves all Submodels from the  Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoGetSubmodelsFromShell(aasId: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoGetSubmodelsFromShell(aasId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Invokes a specific operation from the Submodel synchronously or asynchronously
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort Submodel&#x27;s short id
         * @param {string} idShortPathToOperation The IdShort path to the Operation
         * @param {InvocationRequest} [body] The parameterized request object for the invocation
         * @param {boolean} [async] Determines whether the execution of the operation is asynchronous (true) or not (false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoInvokeOperationByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, body?: InvocationRequest, async?: boolean, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoInvokeOperationByIdShort(aasId, submodelIdShort, idShortPathToOperation, body, async, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates or updates a Submodel-Element at the Submodel
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {SubmodelElement} [body] The Submodel-Element object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelElement(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: SubmodelElement, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoPutSubmodelElement(aasId, submodelIdShort, seIdShortPath, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Updates the Submodel-Element's value
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
         * @param {any} [body] The new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: any, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoPutSubmodelElementValueByIdShort(aasId, submodelIdShort, seIdShortPath, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates or updates a Submodel to an existing Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {Submodel} [body] The serialized Submodel object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellRepoPutSubmodelToShell(aasId: string, submodelIdShort: string, body?: Submodel, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellRepoPutSubmodelToShell(aasId, submodelIdShort, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves the Submodel from the Asset Administration Shell
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {string} submodelIdShort The Submodel&#x27;s short id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId: string, submodelIdShort: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId, submodelIdShort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shellsAasIdGet(aasId: string, options?: any) {
            return AssetAdministrationShellRepositoryApiFp(configuration).shellsAasIdGet(aasId, options)(fetch, basePath);
        },
    };
};

/**
 * AssetAdministrationShellRepositoryApi - object-oriented interface
 * @export
 * @class AssetAdministrationShellRepositoryApi
 * @extends {BaseAPI}
 */
export class AssetAdministrationShellRepositoryApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a specific Asset Administration Shell at the Asset Administration Shell repository
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public deleteAssetAdministrationShellById(aasId: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).deleteAssetAdministrationShellById(aasId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves all Asset Administration Shells from the Asset Administration Shell repository
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public getAllAssetAdministrationShells(options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).getAllAssetAdministrationShells(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public getAssetAdministrationShellById(aasId: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).getAssetAdministrationShellById(aasId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates or updates a Asset Administration Shell at the Asset Administration Shell repository
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {AssetAdministrationShell} [body] The Asset Administration Shell
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public putAssetAdministrationShell(aasId: string, body?: AssetAdministrationShell, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).putAssetAdministrationShell(aasId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a specific Submodel-Element from the Submodel
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoDeleteSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoDeleteSubmodelElementByIdShort(aasId, submodelIdShort, seIdShortPath, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a specific Submodel from the Asset Administration Shell
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoDeleteSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoDeleteSubmodelFromShellByIdShort(aasId, submodelIdShort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the result of an asynchronously started operation
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort Submodel&#x27;s short id
     * @param {string} idShortPathToOperation The IdShort path to the Operation
     * @param {string} requestId The request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetInvocationResultByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, requestId: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetInvocationResultByIdShort(aasId, submodelIdShort, idShortPathToOperation, requestId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a specific Submodel-Element from the Submodel
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetSubmodelElementByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetSubmodelElementByIdShort(aasId, submodelIdShort, seIdShortPath, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the value of a specific Submodel-Element from the Submodel
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetSubmodelElementValueByIdShort(aasId, submodelIdShort, seIdShortPath, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves all Submodel-Elements from the Submodel
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetSubmodelElements(aasId: string, submodelIdShort: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetSubmodelElements(aasId, submodelIdShort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the Submodel from the Asset Administration Shell
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetSubmodelFromShellByIdShort(aasId: string, submodelIdShort: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetSubmodelFromShellByIdShort(aasId, submodelIdShort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the minimized version of a Submodel, i.e. only the values of SubmodelElements are serialized and returned
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetSubmodelValues(aasId: string, submodelIdShort: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetSubmodelValues(aasId, submodelIdShort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves all Submodels from the  Asset Administration Shell
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoGetSubmodelsFromShell(aasId: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoGetSubmodelsFromShell(aasId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Invokes a specific operation from the Submodel synchronously or asynchronously
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort Submodel&#x27;s short id
     * @param {string} idShortPathToOperation The IdShort path to the Operation
     * @param {InvocationRequest} [body] The parameterized request object for the invocation
     * @param {boolean} [async] Determines whether the execution of the operation is asynchronous (true) or not (false)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoInvokeOperationByIdShort(aasId: string, submodelIdShort: string, idShortPathToOperation: string, body?: InvocationRequest, async?: boolean, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoInvokeOperationByIdShort(aasId, submodelIdShort, idShortPathToOperation, body, async, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates or updates a Submodel-Element at the Submodel
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
     * @param {SubmodelElement} [body] The Submodel-Element object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoPutSubmodelElement(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: SubmodelElement, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoPutSubmodelElement(aasId, submodelIdShort, seIdShortPath, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Updates the Submodel-Element's value
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {string} seIdShortPath The Submodel-Element&#x27;s IdShort-Path
     * @param {any} [body] The new value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoPutSubmodelElementValueByIdShort(aasId: string, submodelIdShort: string, seIdShortPath: string, body?: any, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoPutSubmodelElementValueByIdShort(aasId, submodelIdShort, seIdShortPath, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates or updates a Submodel to an existing Asset Administration Shell
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {Submodel} [body] The serialized Submodel object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellRepoPutSubmodelToShell(aasId: string, submodelIdShort: string, body?: Submodel, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellRepoPutSubmodelToShell(aasId, submodelIdShort, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the Submodel from the Asset Administration Shell
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {string} submodelIdShort The Submodel&#x27;s short id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId: string, submodelIdShort: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellsAasIdAasSubmodelsSubmodelIdShortGet(aasId, submodelIdShort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public shellsAasIdGet(aasId: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).shellsAasIdGet(aasId, options)(this.fetch, this.basePath);
    }

}
