import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { SubmodelElementCollection } from 'api/v3/aas_core_meta/types';
import { NestedContentWrapper } from 'components/basics/NestedContentWrapper';
import { messages } from 'i18n/localization';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubmodelElementRenderer } from './SubmodelElementRenderer';

enum ExpandButtonText {
    show = 'show',
    hide = 'hide',
}

type SubModelElComponentProps = {
    readonly subElCol: SubmodelElementCollection;
};

export function SubmodelElementCollectionComponent(props: SubModelElComponentProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const componentList: ReactNode[] = [];
    const { subElCol } = props;

    if (
        !subElCol.value ||
        (Array.isArray(subElCol.value) && !subElCol.value?.length) ||
        !Object.keys(subElCol.value).length
    ) {
        return <></>;
    }
    subElCol.value.forEach((val, index) => {
        componentList.push(<SubmodelElementRenderer key={index} submodelElement={val} hasDivider={!(index === 0)} />);
    });

    return (
        <Box>
            <Button
                variant="outlined"
                startIcon={isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                sx={{ my: 1 }}
                onClick={() => setIsExpanded(!isExpanded)}
                data-testid="submodel-dropdown-button"
            >
                <FormattedMessage
                    {...messages.mnestix.showEntriesButton[isExpanded ? ExpandButtonText.hide : ExpandButtonText.show]}
                    values={{ count: `${Object.keys(subElCol.value).length}` }}
                />
            </Button>
            {!!isExpanded && <NestedContentWrapper>{componentList}</NestedContentWrapper>}
        </Box>
    );
}
