import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';
import SignInButton from '../azureAuthentication/SignInButton';
import { ReactComponent as AuthenticationLock } from 'assets/authentication_lock.svg';
import { FormattedMessage } from 'react-intl';
import { messages } from 'i18n/localization';

export function PrivateRoute({ children }: { children: JSX.Element }) {
    return (
        <>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', m: 3, mt: 5 }}
                >
                    <Typography variant="h2" sx={{ mb: 2 }} color="primary" align="center">
                        <FormattedMessage {...messages.mnestix.authenticationNeeded} />
                    </Typography>
                    <AuthenticationLock />
                    <SignInButton />
                </Box>
            </UnauthenticatedTemplate>
        </>
    );
}

export default PrivateRoute;
