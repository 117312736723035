import React, { PropsWithChildren } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { mnestixFetch } from '../../api/infrastructure';
import { LookupClient, TemplateClient } from '../../generated-api/clients.g';

const ApiContext = React.createContext<Apis | null>(null);
export const ApiProvider = (props: PropsWithChildren) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const apis = {
        templateClientWithAuth: new TemplateClient(window.location.origin, mnestixFetch(instance, account)),
        lookupClient: new LookupClient(window.location.origin, mnestixFetch(instance, account)),
    };

    return <ApiContext.Provider value={apis}>{props.children}</ApiContext.Provider>;
};

export function useApis(): Apis {
    const context = React.useContext(ApiContext);
    if (context === null) {
        throw new Error('useApis must be used within a ApiProvider');
    }
    return context;
}

export type Apis = {
    templateClientWithAuth: TemplateClient;
    lookupClient: LookupClient;
};
