import { Internationalization } from './i18n/Internationalization';
import { LayoutRoot } from './layout/LayoutRoot';
import { CustomThemeProvider } from './theme/CustomThemeProvider';
import { CurrentAasContextProvider } from './contexts/CurrentAasContext';
import { NotificationContextProvider } from 'contexts/NotificationContext';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { ApiProvider } from './components/azureAuthentication/ApiProvider';

const msalInstance = new PublicClientApplication(msalConfig as Configuration);

// The main entry point of the frontend.
export function Bootstrapper() {
    return (
        <MsalProvider instance={msalInstance}>
            <ApiProvider>
                <Internationalization>
                    <CustomThemeProvider>
                        <CurrentAasContextProvider>
                            <NotificationContextProvider>
                                <LayoutRoot />
                            </NotificationContextProvider>
                        </CurrentAasContextProvider>
                    </CustomThemeProvider>
                </Internationalization>
            </ApiProvider>
        </MsalProvider>
    );
}
