import isomorphicFetch from "isomorphic-fetch";
import url from "url";
import {BaseAPI, Configuration, FetchAPI, FetchArgs, RequiredError} from "../aasApi";
import {AssetAdministrationShell, Submodel} from "./aas_core_meta/types";
import {encodeBase64} from "util/Base64Util";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 * AssetAdministrationShellRepositoryApi - object-oriented interface
 * @class AssetAdministrationShellRepositoryApi_v3
 * @extends {BaseAPI}
 */
export class AssetAdministrationShellRepositoryApi_v3 extends BaseAPI {
    /**
     * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
     * @param {string} aasId The Asset Administration Shell&#x27;s unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public getAssetAdministrationShellById(aasId: string, options?: any) {
        return AssetAdministrationShellRepositoryApiFp(this.configuration).getAssetAdministrationShellById(aasId, options)(this.fetch, this.basePath);
    }
}

/**
 * AssetAdministrationShellRepositoryApi - functional programming interface
 * @export
 */
export const AssetAdministrationShellRepositoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        getAssetAdministrationShellById(aasId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssetAdministrationShell> { // HINT: AssetAdministrationShell is taken from aas_core_meta
            const localVarFetchArgs = AssetAdministrationShellRepositoryApiFetchParamCreator(configuration).getAssetAdministrationShellById(aasId, options);
            return async (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            };
        }
    }
}

/**
 * AssetAdministrationShellRepositoryApi - fetch parameter creator
 */
export const AssetAdministrationShellRepositoryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Retrieves a specific Asset Administration Shell from the Asset Administration Shell repository
         * @param {string} aasId The Asset Administration Shell&#x27;s unique id
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        getAssetAdministrationShellById(aasId: string, options: any = {}): FetchArgs {
            // verify required parameter 'aasId' is not null or undefined
            if (aasId === null || aasId === undefined) {
                throw new RequiredError('aasId','Required parameter aasId was null or undefined when calling getAssetAdministrationShellById.');
            }
            const localVarPath = `/shells/{aasId}`
                .replace(`{aasId}`, encodeURIComponent(String(aasId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }
    }
}

/**
 * SubmodelRepositoryApi - object-oriented interface
 * @class SubmodelRepositoryApi_v3
 * @extends {BaseAPI}
 */
export class SubmodelRepositoryApi_v3 extends BaseAPI {
    /**
     * @summary Retrieves the meta data of a submodel
     * @param {string} submodelId The Asset Administration Shell&#x27;s unique id
     * @param {*} [options] Override http request option
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public getSubmodelMetaDataById(submodelId: string, options?: any): Promise<Submodel> {
        return SubmodelRepositoryApiFp(this.configuration).getSubmodelMetaDataById(submodelId, options)(this.fetch, this.basePath);
    }

    /**
     * @summary Retrieves the submodel
     * @param {string} submodelId The Submodels unique id
     * @param {*} [options] Override http request option
     * @throws {RequiredError}
     * @memberof AssetAdministrationShellRepositoryApi
     */
    public getSubmodelById(submodelId: string, options?: any): Promise<Submodel> {
        return SubmodelRepositoryApiFp(this.configuration).getSubmodelById(submodelId, options)(this.fetch, this.basePath);
    }
}

/**
 * SubmodelRepositoryApi - functional programming interface
 */
export const SubmodelRepositoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * @summary Retrieves the meta data of a submodel
         * @param {string} submodelId The Submodels unique id
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        getSubmodelMetaDataById(submodelId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Submodel> { // HINT: Submodel is taken from aas_core_meta
            const localVarFetchArgs = SubmodelRepositoryApiFetchParamCreator(configuration).getSubmodelMetaDataById(encodeBase64(submodelId), options);
            return async (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            };
        },
        /**
         * @summary Retrieves the submodel
         * @param {string} submodelId The Submodels unique id
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        getSubmodelById(submodelId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Submodel> {
            const localVarFetchArgs = SubmodelRepositoryApiFetchParamCreator(configuration).getSubmodelById(encodeBase64(submodelId), options);
            return async (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                const response = await fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            };
        }
    }
}

/**
 * SubmodelRepositoryApi - fetch parameter creator
 */
export const SubmodelRepositoryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * @summary Retrieves the meta data of a submodel
         * @param {string} submodelId The Submodels unique id
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        getSubmodelMetaDataById(submodelId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submodelId' is not null or undefined
            if (submodelId === null || submodelId === undefined) {
                throw new RequiredError('submodelId','Required parameter submodelId was null or undefined when calling getSubmodelMetaDataById.');
            }
            const localVarPath = `/submodels/{submodelId}/$metadata?level=core`
                .replace(`{submodelId}`, encodeURIComponent(String(submodelId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @summary Retrieves the submodel
         * @param {string} submodelId The Submodels unique id
         * @param {*} [options] Override http request option
         * @throws {RequiredError}
         */
        getSubmodelById(submodelId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submodelId' is not null or undefined
            if (submodelId === null || submodelId === undefined) {
                throw new RequiredError('submodelId','Required parameter submodelId was null or undefined when calling getSubmodelById.');
            }
            const localVarPath = `/submodels/{submodelId}`
                .replace(`{submodelId}`, encodeURIComponent(String(submodelId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }
    }
}
