import { AssetAdministrationShellRepositoryApi } from './aasApi';
import { ConfigurationShellApi } from './configurationShellApi/configurationShellApi';
import { TemplateShellApi } from './templateShellApi/templateShellApi';
import {AssetAdministrationShellRepositoryApi_v3, SubmodelRepositoryApi_v3} from "./v3/api_v3";

export const repositoryClient = new AssetAdministrationShellRepositoryApi({ basePath: '/repo' });
export const repositoryClient_v3 = new AssetAdministrationShellRepositoryApi_v3({ basePath: '/repo' });
export const configurationClient = new ConfigurationShellApi('/configuration');
export const templatesClient = new TemplateShellApi();
export const submodelClient_v3 = new SubmodelRepositoryApi_v3({ basePath: '/repo' });
