import { Box, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { useCurrentAasContext } from '../../../contexts/CurrentAasContext';
import { repositoryClient_v3 } from 'api';
import { messages } from '../../../i18n/localization';
import { SquaredIconButton } from '../../basics/SquaredIconButton';
import { useNotificationSpawner } from 'hooks/UseNotificationSpawner';
import { encodeBase64 } from 'util/Base64Util';
import { showError } from 'util/ErrorHandlerUtil';

export function ManualAASViewerInput() {
    const [, setCurrentAas] = useCurrentAasContext();
    const [val, setVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const navigate = useNavigate();
    const intl = useIntl();
    const notificationSpawner = useNotificationSpawner();

    const setError = (msg: string) => {
        setIsError(true);
        setErrorText(msg);
    };

    const clearError = () => {
        setIsError(false);
        setErrorText('');
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await repositoryClient_v3.getAssetAdministrationShellById(encodeBase64(val));
            setCurrentAas(response);
            navigate(`/viewer/${encodeBase64(val)}`);
        } catch (e: unknown) {
            setIsLoading(false);
            showError(e, notificationSpawner);
            if (e instanceof Response && e.status === 404) {
                setError(intl.formatMessage(messages.mnestix.notFound));
                return;
            }
            setError(intl.formatMessage(messages.mnestix.unexpectedError));
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        // Allow submit via enter
        if (event.key === 'Enter' && !!val) {
            handleSubmit();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVal(event.target.value);
        clearError();
    };

    return (
        <Box display="flex" justifyContent="center">
            <TextField
                id="manual-input"
                label={<FormattedMessage {...messages.mnestix.aasId} />}
                error={isError}
                helperText={errorText}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                data-testid="aasId-input"
            />
            <SquaredIconButton
                sx={{ ml: 1 }}
                endIcon={<ArrowForward />}
                disabled={!val}
                loading={isLoading}
                onClick={handleSubmit}
                data-testid="aasId-submit-button"
            />
        </Box>
    );
}
