import { PropsWithChildren } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { deDE } from '@mui/material/locale';
import { CssBaseline } from '@mui/material';

// Provides the theme information for all MaterialUI components.
export function CustomThemeProvider(props: PropsWithChildren<{ readonly skipStyleReset?: boolean }>) {
    return (
        <ThemeProvider theme={createTheme(theme, deDE)}>
            {!props.skipStyleReset && <CssBaseline />}
            {props.children}
        </ThemeProvider>
    );
}
