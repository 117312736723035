import { encodeBase64 } from 'util/Base64Util';
import { Submodel } from "../v3/aas_core_meta/types";

export class TemplateShellApi {
    basePathOwnApi: string;
    basePathCustoms: string;
    basePathDefaults: string;
       
    constructor() {
        this.basePathOwnApi = '/api/Template'
        this.basePathCustoms = '/templates/custom'
        this.basePathDefaults = '/templates/default'
    }
    public getDefaults(token : string): Promise<Submodel[]> {        
        return fetch(this.basePathOwnApi + '/allDefaultSubmodels', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response;
            }
        })
    }
    public getCustoms(token : string): Promise<Submodel[]> {
        return fetch(this.basePathOwnApi + '/allCustomSubmodels', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response;
            }
        })
    }

    public getCustom(token : string, submodelIdShort: string): Promise<Submodel> {
        return fetch(this.basePathOwnApi + '/CustomSubmodel/' + encodeBase64(submodelIdShort), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response;
            }
        })
    }

    public deleteCustomById(token: string, id: string): Promise<string | number> {
        // We use the regular delete endpoint, which expects an idShort, but because of our backend interception, we saved the actual id in the idShort field earlier.
        // That's why this works.
        return fetch(this.basePathCustoms + '/' + encodeBase64(id), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
        }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.status;
            } else {
                throw response;
            }
        })
    }
}
