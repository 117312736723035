import { Entity, ISubmodelElement, SubmodelElementCollection } from 'api/v3/aas_core_meta/types';
import { SubmodelElementSemanticId } from 'enums/SubmodelElementSemanticId.enum';
import { AddressComponent } from '../submodel-elements/address-component/AddressComponent';
import { ContactInformationComponent } from '../submodel-elements/contact-information-component/ContactInformationComponent';
import { DocumentComponent } from '../submodel-elements/document-component/DocumentComponent';
import { MarkingsComponent } from '../submodel-elements/MarkingsComponent';
import { SubmodelElementRenderer } from '../submodel-elements/SubmodelElementRenderer';

type SubmodelDetailListProps = {
    submodelElements: ISubmodelElement[];
};

export function SubmodelDetailList(props: SubmodelDetailListProps) {
    // Entity element always has a line at the bottom, so we don't need an extra line on the following element
    const isEntityElementAbove = (index: number) => props.submodelElements[index - 1] instanceof Entity;
    const hasDivider = (index: number) => !(index === 0) && !isEntityElementAbove(index);

    return (
        <>
            {props.submodelElements.map((el, index) => {
                switch (el.semanticId?.keys?.[0]?.value) {
                    case SubmodelElementSemanticId.Address:
                        return (
                            <AddressComponent
                                key={index}
                                submodelElement={el as SubmodelElementCollection}
                                hasDivider={hasDivider(index)}
                            />
                        );
                    case SubmodelElementSemanticId.ContactInformation:
                        return (
                            <ContactInformationComponent
                                key={index}
                                submodelElement={el as SubmodelElementCollection}
                                hasDivider={hasDivider(index)}
                            />
                        );
                    case SubmodelElementSemanticId.Markings:
                        return (
                            <MarkingsComponent
                                key={index}
                                submodelElement={el as SubmodelElementCollection}
                                hasDivider={hasDivider(index)}
                            />
                        );
                    case SubmodelElementSemanticId.Document:
                        return (
                            <DocumentComponent
                                key={index}
                                submodelElement={el as SubmodelElementCollection}
                                hasDivider={hasDivider(index)}
                            />
                        );
                    default:
                        return (
                            <SubmodelElementRenderer key={index} submodelElement={el} hasDivider={hasDivider(index)} />
                        );
                }
            })}
        </>
    );
}
